import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'
import { FixedContainer } from '../styled/containers'
import Courses from 'components/shared/courses'
import { Button } from 'components/styled/button'

// ASSETS
import { SCREEN } from 'styles/screens'
import { COURSES } from 'data/courses'
import ArrowIcon from 'svgs/arrow_top_right.svg'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  margin-bottom: 60px;
  .flex-wrap {
    flex-wrap: wrap;
  }
  .fw-normal h3 {
    font-weight: normal;
  }
  .titleButton {
    padding-left: 24px;
    padding-right: 24px;
    > svg {
      margin-left: 14px;
    }
  }
  .align-items-start {
    align-items: start;
  }
  .justify-content-lg-between {
    justify-content: space-between
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    .justify-content-lg-between {
      justify-content: flex-end;
    }
    .titleButton {
      margin-top: -50px;
      margin-bottom: 50px;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .titleButton {
      margin-top: auto;
    }
    .w-mobile-100 {
      width: 100%;
    }
    padding: 16px;
  }
`

const StyledFixedContainer = styled(FixedContainer)`
  flex-direction: column;
  align-items: stretch;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h2 {
      font-size: 1.5rem;
    }
  }
`

const CoursesSection = ({ programs }) => (
  <Container>
    <StyledFixedContainer>
      <div className='d-flex align-items-start justify-content-lg-between flex-wrap'>
        <div className='w-mobile-100 fw-normal'>
        <ShadowText highlight>
          <h3>{getTranslation("upcoming","Upcoming ", false)}<b>{getTranslation("Online Courses","Online Courses",false)}</b></h3>
        </ShadowText>
        </div>

        <Button className="titleButton">{getTranslation("Explore All Courses","Explore All Courses",false)} <ArrowIcon/></Button>
      </div>

      <Courses programs={programs} />
    </StyledFixedContainer>
  </Container>
)

export default CoursesSection
