import * as React from 'react';
import { ShadowText } from '../styled/typography'
import Faq from '../shared/faq'
import styled from 'styled-components'
import { SCREEN } from '../../styles/screens'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;

  > div:first-child {
    align-self: flex-start;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    h2 {
      font-size: clamp(1.2rem, 4vh, 2.5rem);
    }
  }
`


const StyledFixedContainer = styled(FixedContainer)`
  flex-direction: column;
  align-items: stretch;
  .mw-8percent {
    max-width: 8%;
  }
  .mb-100px {
    margin-bottom: 60px !important;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h2 {
      font-size: 1.5rem;
    }

    .mb-100px {
      margin-bottom: 20px !important;
    }
  }
`

const FaqSection = ({questions})=>{
  return <Container className="mb-120px">
    <StyledFixedContainer className="mw-8percent">
      <ShadowText highlight>
        <h3>FAQ</h3>
      </ShadowText>
      <Faq questions={questions} />
    </StyledFixedContainer>
  </Container>
}
export default FaqSection;
