import * as React from 'react'
import styled from 'styled-components'

// ASSETS
import ArrowIcon from 'svgs/arrow_top_right.svg'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'

const Courses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Course = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  border-bottom: 3px solid var(--primary);
  padding: 48px 0;
  cursor: pointer;
  transition: all 400ms;

  > h5,
  h6 {
    margin: 0 !important;
    transition: all 400ms !important;
  }

  > h5 {
    font-weight: 400 !important;
    flex: 0 0 25% !important;
  }

  > h6 {
    font-size: 27px !important;
    font-weight: bold !important;
    flex: 0 1 40%;
  }

  > p {
    transition: all 400ms !important;
    opacity: 0;

    > a {
      text-decoration: none;
      color: inherit;
    }
  }

  &:hover {
    background-color: var(--secondary);

    > h5 {
      transform: translateY(12px) !important;
    }

    > h6 {
      transform: translate(-16px, 12px) !important;
      color: var(--white);
    }

    > p {
      opacity: 1;
    }
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    border-bottom: 3px solid var(--primary);
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 24px 0;
    > h5 {
      font-weight: 400 !important; 
      font-size: 15px;
      flex: 0 0 35% !important;
    }

    h6 {
      margin-left: 10px !important;
      flex: 1 1 40% !important;
      font-size: 16px !important;
      font-weight: 500 !important;
    }

    p {
      display: none;
    }

    &:hover {
      background-color: unset;

      > h5 {
        transform: unset !important;
      }

      > h6 {
        transform: unset !important;
        color: unset !important;
      }
    }
  }
`

const RenderCourses = ({ programs }) => (
  <Courses>
    {programs.map((program, idx) => (
      <Course key={idx}>
        <h5 onClick={() => {
              window.location.href = `/program/${program.key}`
            }}>{program.data.duration}</h5>
        <h6 onClick={() => {
              window.location.href = `/program/${program.key}`
            }}>{program.data.home_title}</h6>
        {program.enabled ? (
          <p>
            <a href={`/program/${program.key}`}>
              {getTranslation('check_details_enabled', 'Check Details')}{' '}
              <ArrowIcon />
            </a>
          </p>
        ) : (
          <p>
            {getTranslation('check_details', 'check details')} <ArrowIcon />
          </p>
        )}
      </Course>
    ))}
  </Courses>
)

export default RenderCourses
